const menuItems = [
  {
    path: '/#home',
    label: 'Home',
  },
  {
    path: '/#programs',
    label: 'Programs',
  },
  {
    path: '/#services',
    label: 'Services',
  },
  {
    path: '/#faq',
    label: 'FAQ',
  },
  {
    path: '/team#value',
    label: 'Value',
  },
  {
    path: '/team#team',
    label: 'Team',
  },
  {
    path: '/team#contact',
    label: 'Contact',
  },
];

export default menuItems;
