import React from "react";

const Logo = ({ white, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="30"
      version="1"
      viewBox="0 -10 375 75"
    >
      <defs>
        <clipPath id="0fb16fd2d0">
          <path d="M141.258 7.91H181v38.25h-39.742zm0 0"></path>
        </clipPath>
      </defs>
      <path
        d="M25.25-38.797v7.75H11.656v7.719H24.22v7.765H11.656V0H2.828v-38.797zm0 0"
        transform="translate(13.002 46.04)"
      ></path>
      <path
        d="M22.531-38.797L38.188 0h-9.5l-2.875-7.766H11.046L8.187 0h-9.5l15.657-38.797zm.282 23.281l-4.329-11.296h-.093l-4.328 11.296zm0 0"
        transform="translate(39.656 46.04)"
      ></path>
      <path
        d="M17.125-39.5c2.094 0 4.145.309 6.156.922 2.008.605 3.5 1.203 4.469 1.797l1.406.937-3.578 7.063a25.309 25.309 0 00-1.172-.719c-.5-.3-1.445-.703-2.828-1.203-1.375-.508-2.656-.766-3.844-.766-1.48 0-2.62.32-3.422.953-.792.625-1.187 1.47-1.187 2.532 0 .53.188 1.027.563 1.484.374.46 1.015.95 1.921 1.469a32.81 32.81 0 002.407 1.234c.687.313 1.754.781 3.203 1.406 2.5 1.075 4.633 2.528 6.406 4.36 1.77 1.836 2.656 3.906 2.656 6.218 0 2.231-.402 4.18-1.203 5.844-.793 1.657-1.887 2.961-3.281 3.907a15.21 15.21 0 01-4.64 2.078c-1.688.457-3.524.687-5.5.687-1.688 0-3.352-.172-4.985-.515-1.625-.344-2.992-.77-4.094-1.282A46.805 46.805 0 013.61-2.625c-.875-.508-1.515-.938-1.921-1.281l-.61-.516L5.5-11.859c.375.324.89.73 1.547 1.218.664.48 1.836 1.125 3.516 1.938 1.675.812 3.156 1.219 4.437 1.219 3.727 0 5.594-1.266 5.594-3.797 0-.54-.137-1.035-.407-1.485-.261-.457-.73-.91-1.406-1.359A15.925 15.925 0 0017-15.203c-.523-.27-1.371-.68-2.547-1.235-1.168-.55-2.031-.968-2.594-1.25-2.355-1.156-4.18-2.601-5.468-4.343-1.282-1.739-1.922-3.614-1.922-5.625 0-3.477 1.285-6.32 3.86-8.532 2.57-2.207 5.503-3.312 8.796-3.312zm0 0"
        transform="translate(76.51 46.04)"
      ></path>
      <path
        d="M.375-31.047v-7.75h27.281v7.75h-9.218V0H9.594v-31.047zm0 0"
        transform="translate(108.1 46.04)"
      ></path>
      <path
        d="M.375-31.047v-7.75h27.281v7.75h-9.218V0H9.594v-31.047zm0 0"
        transform="translate(174.637 46.04)"
      ></path>
      <path
        d="M2.828-38.797h13.875c3.727 0 6.766.996 9.11 2.984 2.351 1.993 3.53 4.836 3.53 8.532 0 2.855-.714 5.246-2.14 7.172-1.43 1.93-3.36 3.289-5.797 4.078L34.75 0H23.516l-11.86-15.328V0H2.828zm8.828 17.203h1.047c.813 0 1.5-.015 2.063-.047.562-.03 1.203-.14 1.921-.328.727-.187 1.313-.453 1.75-.797.438-.343.813-.843 1.125-1.5.313-.656.47-1.453.47-2.39 0-.946-.157-1.75-.47-2.407-.312-.656-.687-1.156-1.125-1.5-.437-.351-1.023-.625-1.75-.812-.718-.188-1.359-.297-1.921-.328a38.116 38.116 0 00-2.063-.047h-1.047zm0 0"
        transform="translate(202.653 46.04)"
      ></path>
      <path
        d="M22.531-38.797L38.188 0h-9.5l-2.875-7.766H11.046L8.187 0h-9.5l15.657-38.797zm.282 23.281l-4.329-11.296h-.093l-4.328 11.296zm0 0"
        transform="translate(236.31 46.04)"
      ></path>
      <path
        d="M23.469-8c1.343 0 2.648-.203 3.922-.61 1.27-.406 2.203-.796 2.797-1.171l.906-.61 3.75 7.516c-.125.094-.297.227-.516.39-.219.157-.73.45-1.531.876-.793.418-1.649.78-2.563 1.093-.906.313-2.101.594-3.578.844-1.468.25-2.984.375-4.547.375-3.605 0-7.027-.883-10.265-2.656A21.176 21.176 0 014.03-9.266c-1.968-3.101-2.953-6.457-2.953-10.062 0-2.727.578-5.348 1.734-7.86 1.165-2.507 2.711-4.656 4.641-6.437 1.926-1.79 4.176-3.219 6.75-4.281 2.57-1.063 5.207-1.594 7.906-1.594 2.5 0 4.805.297 6.922.89 2.125.594 3.625 1.188 4.5 1.782l1.313.89-3.75 7.532c-.219-.188-.555-.41-1-.672-.438-.27-1.32-.625-2.64-1.063a12.331 12.331 0 00-3.985-.672c-2.137 0-4.055.352-5.75 1.047-1.688.688-3.028 1.602-4.016 2.735a12.689 12.689 0 00-2.25 3.64 10.488 10.488 0 00-.781 3.97c0 2.948 1.101 5.589 3.312 7.921 2.22 2.336 5.38 3.5 9.485 3.5zm0 0"
        transform="translate(273.165 46.04)"
      ></path>
      <path
        d="M38.14 0H25.97L11.656-16.453V0H2.828v-38.797h8.828v15.844L24.97-38.797h10.734l-15.625 18.39zm0 0"
        transform="translate(308.562 46.04)"
      ></path>
      <path
        fill="#5271ff"
        d="M8.188-10.063c1.5 0 2.773.528 3.828 1.579 1.05 1.054 1.578 2.328 1.578 3.828 0 1.468-.528 2.73-1.578 3.781C10.96.175 9.687.703 8.187.703c-1.48 0-2.746-.527-3.796-1.578-1.043-1.05-1.563-2.313-1.563-3.781 0-1.5.52-2.774 1.563-3.828 1.05-1.051 2.316-1.579 3.796-1.579zm0 0"
        transform="translate(345.574 46.04)"
      ></path>
      <g clipPath="url(#0fb16fd2d0)">
        <path
          fill="#5271ff"
          d="M180.828 26.988a.376.376 0 00-.172-.332L154.262 8.004a.371.371 0 00-.418-.024.353.353 0 00-.223.356v7.941l-11.715-8.273a.384.384 0 00-.426-.024.37.37 0 00-.214.356V45.64c.004.16.078.277.214.355a.551.551 0 00.196.047c.086 0 .16-.027.23-.078l11.715-8.278v7.954a.37.37 0 00.223.355.393.393 0 00.187.047.452.452 0 00.23-.078l26.395-18.645a.39.39 0 00.172-.332zm-26.39 17.864v-7.743L168.3 27.32a.404.404 0 00.172-.332.399.399 0 00-.172-.332l-13.863-9.8V9.12l25.277 17.867zm0 0"
        ></path>
      </g>
      <path
        fill="#a6a6a6"
        d="M5.719-9.86L9.703 0H7.297l-.734-1.969h-3.75L2.078 0H-.328L3.64-9.86zm.078 5.922L4.703-6.812h-.031L3.578-3.938zm0 0"
        transform="translate(33.218 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M5.969-2.031c.343 0 .676-.051 1-.156.32-.102.554-.204.703-.297l.234-.157.953 1.907a.803.803 0 01-.14.109c-.055.031-.184.105-.39.219-.2.105-.415.199-.642.281-.23.074-.53.14-.906.203a7.034 7.034 0 01-1.156.094c-.918 0-1.79-.223-2.61-.672a5.327 5.327 0 01-1.984-1.844c-.5-.789-.75-1.644-.75-2.562 0-.696.145-1.364.438-2 .289-.633.68-1.18 1.172-1.64a5.69 5.69 0 011.718-1.095 5.262 5.262 0 012.016-.406c.633 0 1.219.078 1.75.235.54.148.922.296 1.14.453l.344.234-.953 1.906a1.858 1.858 0 00-.265-.172c-.106-.07-.325-.16-.657-.265a3.151 3.151 0 00-1.015-.172c-.543 0-1.032.09-1.469.266-.43.18-.766.414-1.016.703a3.15 3.15 0 00-.578.921 2.752 2.752 0 00-.187 1c0 .75.281 1.422.844 2.016.562.594 1.363.89 2.406.89zm0 0"
        transform="translate(50.672 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M5.719-9.86L9.703 0H7.297l-.734-1.969h-3.75L2.078 0H-.328L3.64-9.86zm.078 5.922L4.703-6.812h-.031L3.578-3.938zm0 0"
        transform="translate(67.755 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M.719-9.86h2.86c1.362 0 2.53.47 3.5 1.407.976.937 1.468 2.11 1.468 3.515 0 1.418-.485 2.594-1.453 3.532C6.125-.47 4.954 0 3.578 0H.718zm2.25 7.844h.344c.82 0 1.492-.273 2.015-.828.52-.55.785-1.242.797-2.078 0-.844-.266-1.539-.797-2.094-.523-.562-1.195-.843-2.016-.843H2.97zm0 0"
        transform="translate(85.208 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M6.36-9.86v1.97H2.968v1.968h3.25v1.969h-3.25v1.984h3.39V0H.72v-9.86zm0 0"
        transform="translate(102.112 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M8.875 0l-.469-5.844h-.062L6.328-.062H4.984L2.97-5.845h-.063L2.437 0H.188l.891-9.86h2.39l2.188 5.688 2.188-5.687h2.39L11.11 0zm0 0"
        transform="translate(117.129 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M2.969-9.86V0H.719v-9.86zm0 0"
        transform="translate(136.517 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M5.969-2.031c.343 0 .676-.051 1-.156.32-.102.554-.204.703-.297l.234-.157.953 1.907a.803.803 0 01-.14.109c-.055.031-.184.105-.39.219-.2.105-.415.199-.642.281-.23.074-.53.14-.906.203a7.034 7.034 0 01-1.156.094c-.918 0-1.79-.223-2.61-.672a5.327 5.327 0 01-1.984-1.844c-.5-.789-.75-1.644-.75-2.562 0-.696.145-1.364.438-2 .289-.633.68-1.18 1.172-1.64a5.69 5.69 0 011.718-1.095 5.262 5.262 0 012.016-.406c.633 0 1.219.078 1.75.235.54.148.922.296 1.14.453l.344.234-.953 1.906a1.858 1.858 0 00-.265-.172c-.106-.07-.325-.16-.657-.265a3.151 3.151 0 00-1.015-.172c-.543 0-1.032.09-1.469.266-.43.18-.766.414-1.016.703a3.15 3.15 0 00-.578.921 2.752 2.752 0 00-.187 1c0 .75.281 1.422.844 2.016.562.594 1.363.89 2.406.89zm0 0"
        transform="translate(148.286 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M5.969-2.031c.343 0 .676-.051 1-.156.32-.102.554-.204.703-.297l.234-.157.953 1.907a.803.803 0 01-.14.109c-.055.031-.184.105-.39.219-.2.105-.415.199-.642.281-.23.074-.53.14-.906.203a7.034 7.034 0 01-1.156.094c-.918 0-1.79-.223-2.61-.672a5.327 5.327 0 01-1.984-1.844c-.5-.789-.75-1.644-.75-2.562 0-.696.145-1.364.438-2 .289-.633.68-1.18 1.172-1.64a5.69 5.69 0 011.718-1.095 5.262 5.262 0 012.016-.406c.633 0 1.219.078 1.75.235.54.148.922.296 1.14.453l.344.234-.953 1.906a1.858 1.858 0 00-.265-.172c-.106-.07-.325-.16-.657-.265a3.151 3.151 0 00-1.015-.172c-.543 0-1.032.09-1.469.266-.43.18-.766.414-1.016.703a3.15 3.15 0 00-.578.921 2.752 2.752 0 00-.187 1c0 .75.281 1.422.844 2.016.562.594 1.363.89 2.406.89zm0 0"
        transform="translate(176.72 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M.281-4.938c0-1.425.485-2.632 1.453-3.625.97-.988 2.141-1.484 3.516-1.484s2.54.496 3.5 1.485c.969.98 1.453 2.187 1.453 3.624 0 1.45-.476 2.665-1.422 3.641C7.832-.317 6.656.172 5.25.172c-1.45 0-2.64-.484-3.578-1.453-.93-.977-1.39-2.196-1.39-3.657zm2.422 0c0 .524.078.997.235 1.422.156.43.43.79.828 1.079.406.292.898.437 1.484.437.832 0 1.46-.285 1.89-.86.426-.57.641-1.265.641-2.078 0-.8-.218-1.488-.656-2.062-.43-.582-1.055-.875-1.875-.875-.813 0-1.445.293-1.89.875-.438.574-.657 1.262-.657 2.063zm0 0"
        transform="translate(193.803 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M8.297-9.86v6.36c0 1.21-.328 2.125-.985 2.75-.656.617-1.617.922-2.875.922-1.25 0-2.203-.305-2.859-.922C.922-1.375.594-2.29.594-3.5v-6.36h2.25v5.704c0 .793.125 1.343.375 1.656.25.313.656.469 1.219.469.57 0 .984-.156 1.234-.469.25-.313.375-.863.375-1.656V-9.86zm0 0"
        transform="translate(212.367 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M2.969-6.125V0H.719v-9.86h2.25L6.89-3.733h.015V-9.86h2.25V0h-2.25L2.984-6.125zm0 0"
        transform="translate(229.342 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M4.344-10.047c.539 0 1.066.078 1.578.235.508.156.883.312 1.125.468l.36.235L6.5-7.313a5.552 5.552 0 00-.297-.187 5.339 5.339 0 00-.719-.297A2.842 2.842 0 004.5-8c-.375 0-.668.086-.875.25a.77.77 0 00-.297.64c0 .137.047.262.14.376.102.117.27.242.5.375.227.125.43.23.61.312.176.086.445.203.813.36.632.273 1.175.64 1.625 1.109.457.46.687.984.687 1.578 0 .563-.105 1.059-.312 1.484-.2.418-.477.746-.829.985A3.76 3.76 0 015.375 0c-.43.113-.89.172-1.39.172-.438 0-.868-.043-1.282-.125a4.332 4.332 0 01-1.031-.328c-.281-.133-.54-.266-.766-.39a2.799 2.799 0 01-.469-.313l-.156-.141 1.125-1.89c.094.085.223.187.39.312.165.125.462.293.891.5.426.2.801.297 1.126.297.945 0 1.421-.32 1.421-.969a.721.721 0 00-.109-.375c-.063-.113-.184-.227-.36-.344a3.515 3.515 0 00-.452-.265 9.368 9.368 0 00-.641-.313 49.904 49.904 0 01-.656-.328c-.606-.29-1.07-.656-1.391-1.094a2.396 2.396 0 01-.484-1.437c0-.883.32-1.61.968-2.172.657-.563 1.399-.844 2.235-.844zm0 0"
        transform="translate(247.297 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M6.36-9.86v1.97H2.968v1.968h3.25v1.969h-3.25v1.984h3.39V0H.72v-9.86zm0 0"
        transform="translate(263.413 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M2.969-9.86v7.891h3.39V0H.72v-9.86zm0 0"
        transform="translate(278.43 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M2.969-9.86V0H.719v-9.86zm0 0"
        transform="translate(293.268 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M2.969-6.125V0H.719v-9.86h2.25L6.89-3.733h.015V-9.86h2.25V0h-2.25L2.984-6.125zm0 0"
        transform="translate(305.037 64.165)"
      ></path>
      <path
        fill="#a6a6a6"
        d="M9.64-5.516v2.141C9.38-2.207 8.817-1.32 7.954-.719a4.923 4.923 0 01-2.875.89C3.742.172 2.61-.32 1.672-1.312c-.93-1-1.39-2.203-1.39-3.609 0-1.437.456-2.648 1.374-3.64.914-.989 2.055-1.485 3.422-1.485 1.602 0 2.867.563 3.797 1.688L7.406-6.734c-.523-.852-1.25-1.282-2.187-1.282-.7 0-1.293.305-1.782.907-.492.605-.734 1.336-.734 2.187 0 .836.242 1.547.735 2.14.488.595 1.082.891 1.78.891.571 0 1.067-.171 1.485-.515.426-.344.64-.805.64-1.39H5.079v-1.72zm0 0"
        transform="translate(322.992 64.165)"
      ></path>
    </svg>
  );
}
export default Logo;